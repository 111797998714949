import {Injectable} from "@angular/core";
import {MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";

type Severities = "success" | "info" | "warn" | "error";

@Injectable({
  providedIn: "root"
})
export class NotificationsService {
  constructor(private _messageService: MessageService, private _t: TranslateService) {
  }

  notify(severity: Severities, summary: string, detail: string, key: string = "private") {
    this._messageService.add({severity, summary, detail, key});
  }

  created() {
    this.notify("success", "", this._t.instant("create_Confirm.caption"));
  }

  updated() {
    this.notify("success", "", this._t.instant("updateSucceeded.caption"));
  }
}
