export enum EInterfaceSize {
  medium = 0,
  small = 1,
  large = 2
}

export enum EInterfaceMenu {
  horizontal = 0,
  static = 1,
  overlay = 2
}

export enum EInterfaceLayout {
  compact = 1,
  wide = 0,
}

export enum EInterfaceTabNavigation {
  fixed = 0,
  floating = 1,
}

export enum EInterfaceInputStyle {
  filled = 0,
  outlined = 1,
}

export enum EInterfaceToastPosition {
  topLeft = 0,
  topCenter = 1,
  topRight = 2,
  bottomLeft = 3,
  bottomCenter = 4,
  bottomRight = 5,
}