import {EMfa} from "@shared/enums/EMfa";

export interface IGroup {
  budgetReportTemplate: string;
  country: string;
  groupName: string;
  idGroup: string;
  idTree: string;
  idLatestClient: string;
  idLicenseOwner: number;
  invoiceMethod: number;
  idUser: number;
  licenseOwner: string;
  active: boolean;
  groupCurrency: string;
  claimExportTemplate: string;
  productionReportTemplate: string;
  premiumAllocationTemplate: string;
  financeExportTemplate: string;
  emailAccountSystem: string;
  isAbleCopyInsurance: boolean;
  emailFooter: string;
  defaultClientListReduced: boolean;
  accountsReceivableTemplate: string;
  uwSubPremiums: boolean;
  timetrackStatus: number;
  showGoInsureModule: boolean;
  chatraStatus: number;
  idDefaultInsuredType: number;
  authMfaType: EMfa;
}
