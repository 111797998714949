import {createAction, props} from "@ngrx/store";

export const setInsuranceInfoDirtyState = createAction(
  "[Insurance] Set Insurance Info Dirty State",
  props<{insuranceInfoDirty: boolean}>()
);

export const setInsuranceFinanceDirtyState = createAction(
  "[Insurance] Set Insurance Finance Dirty State",
  props<{insuranceFinanceDirty: boolean}>()
);
export const setInsuranceQuoteDirtyState = createAction(
  "[Insurance] Set Insurance Finance Dirty State",
  props<{insuranceQuoteDirty: boolean}>()
);
export const setInsuranceNextPanelId = createAction(
  "[Insurance] Set Insurance Next panel Id",
  props<{insuranceNextPanelId: string}>()
);

export const updateInsuranceFinanceForm = createAction("[Insurance] Update Insurance Finance Form");
export const updateInsuranceQuoteForm = createAction("[Insurance] Update Insurance Quote Form");
export const updateInsurance = createAction("[Insurance] Update Insurance");
export const changePanel = createAction("[Insurance] Change Panel");
