import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {HttpService} from "@core/services/http.service";
import {tap} from "rxjs/operators";
import {UserService} from "@core/services/user.service";
import {IListOptionModel} from "@private/models/ILocale";

@Injectable()
export class OptionsService {
  private options: BehaviorSubject<IListOptionModel[]> = new BehaviorSubject([]);
  public options$: Observable<IListOptionModel[]> = this.options.asObservable();

  constructor(private user: UserService, private http: HttpService) {
  }

  getOptionsByType(type: string): IListOptionModel[] {
    return this.options.getValue().filter(option => option.definitionName === type);
  }

  getOptionsFor(name: string): Map<number, string> {
    const map: Map<number, string> = new Map();
    this.getOptionsByType(name).forEach(type => map.set(type.optionId, type.text));
    return map;
  }

  // WTC:
  public loadOptions(): Observable<IListOptionModel[]> {
    return this.http.get("common/getListOptions").pipe(
      tap(res => this.options.next(res.data))
    );
  }
}
