import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class LoaderService {
  private showLoader: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public showState$ = this.showLoader.asObservable();

  constructor() {
  }

  public show(): void {
    this.showLoader.next(true);
  }

  public hide(): void {
    this.showLoader.next(false);
  }
}
