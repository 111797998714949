import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {distinctUntilChanged, map} from "rxjs/operators";
import {UserService} from "@core/services/user.service";
import {GroupApiService} from "@core/services/group-api.service";
import {ErrorService} from "@core/services/error.service";
import {IGroup} from "@app/core";

@Injectable()
export class GroupService {
  public currentGroup$: Observable<IGroup>;
  private groups: BehaviorSubject<IGroup[]> = new BehaviorSubject([]);
  public groups$: Observable<IGroup[]> = this.groups.asObservable().pipe(distinctUntilChanged());

  constructor(
    private _user: UserService,
    private _api: GroupApiService,
    private _e: ErrorService
  ) {
    this.currentGroup$ = this._user.user$.pipe(
      map(user => (user && user.currentGroup) ? user.currentGroup : null),
      distinctUntilChanged()
    );
  }

  set setGroups(groups: IGroup[]) {
    this.groups.next(groups);
  }

  get getGroups(): IGroup[] {
    return this.groups.getValue() || [];
  }

  fetchGroups() {
    this._api.getGroups().pipe().subscribe(res => this.setGroups = res.data, err => this._e.showErrors(err));
  }
}
