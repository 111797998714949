import {createSelector} from "@ngrx/store";
import {IInsuranceState} from "@store/state";


const selectInsurance = (state) => state.insurance;

export const selectInsuranceInfoDirtyState = createSelector(
  selectInsurance,
  (state: IInsuranceState) => state.insuranceInfoDirty
);
export const selectInsuranceFinanceDirtyState = createSelector(
  selectInsurance,
  (state: IInsuranceState) => state.insuranceFinanceDirty
);
export const selectInsuranceQuoteDirtyState = createSelector(
  selectInsurance,
  (state: IInsuranceState) => state.insuranceQuoteDirty
);
export const selectInsuranceNextPanelId = createSelector(
  selectInsurance,
  (state: IInsuranceState) => state.insuranceNextPanelId
);
