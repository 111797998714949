import {Action, createReducer, on} from "@ngrx/store";
import {IInsuranceState, insuranceState} from "../state";
import * as InsuranceActions from "../actions";

const reducer = createReducer(
  insuranceState,
  on(InsuranceActions.setInsuranceInfoDirtyState, (state, {insuranceInfoDirty}) => {
    return {...state, insuranceInfoDirty};
  }),
  on(InsuranceActions.setInsuranceFinanceDirtyState, (state, {insuranceFinanceDirty}) => {
    return {...state, insuranceFinanceDirty};
  }),
  on(InsuranceActions.setInsuranceQuoteDirtyState, (state, {insuranceQuoteDirty}) => {
    return {...state, insuranceQuoteDirty};
  }),
  on(InsuranceActions.setInsuranceNextPanelId, (state, {insuranceNextPanelId}) => {
    return {...state, insuranceNextPanelId};
  })
);

export function insuranceReducer(state: IInsuranceState = insuranceState, action: Action) {
  return reducer(state, action);
}
