import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {MenuItem} from "primeng/api";

@Injectable()
export class BreadcrumbService {
  public breadcrumbs$: BehaviorSubject<MenuItem[]> = new BehaviorSubject([]);
  public title$: BehaviorSubject<string> = new BehaviorSubject("");
  public home$: BehaviorSubject<MenuItem> = new BehaviorSubject({icon: "pi pi-home", routerLink: "/panel/dashboard"});

  constructor() {
  }

  setBreadcrumbs(breadcrumbs: MenuItem[], title = "", onlyHeader = false): void {
    if (onlyHeader) {
      this.breadcrumbs$.next([]);
    } else {
      this.breadcrumbs$.next(breadcrumbs);
    }
    this.title$.next(title);
  }
}
