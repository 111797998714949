import {Injectable} from "@angular/core";
import {UserService} from "@core/services/user.service";
import {BehaviorSubject} from "rxjs";

declare const Chatra;

@Injectable({
  providedIn: "root"
})
export class ChatraService {
  private _isOpen = new BehaviorSubject(false);
  public isOpen$ = this._isOpen.asObservable();

  constructor(private user: UserService) {
  }

  public initChatra() {
    try {
      Chatra("kill");
      if (this.user.getUser.idLogin) {
        // @ts-ignore
        window.ChatraSetup = {
          clientId: this.user.getUser.idLogin + this.user.getUser.currentDomain,
          mode: "frame",
          injectTo: "chatra-wrapper",
          colors: {
            buttonText: "#000", /* цвет текста кнопки чата */
            buttonBg: "#FF9318", /* цвет фона кнопки чата */
            clientBubbleBg: "#deffff", // visitor’s message bubble color
            agentBubbleBg: "#FF9318" // agent’s message bubble color
          },
          startHidden: true
        };
      }
      Chatra("restart");
      Chatra("setIntegrationData", {
        "name": (this.user.getUser.userInfo.name) ? this.user.getUser.userInfo.name : null,
        "email": null,
        "phone": null,
        "Login Type": (this.user.getUser.isClientLogin) ? "Client login" : "Internal user"
      });
    } catch (e) {
    }
    try {
      Chatra("show");
    } catch (e) {
    }
  }

  public kill() {
    try {
      Chatra("kill");
    } catch (e) {
    }
  }

  public hide() {
    try {
      Chatra("hide");
    } catch (e) {
    }
  }

  public show() {
    try {
      Chatra("show");
    } catch (e) {
    }
  }

  public toggle() {
    this._isOpen.next(!this._isOpen.getValue());
  }
}
