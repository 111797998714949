import {Injectable} from "@angular/core";
import {HttpService} from "@app/core";
import {Observable} from "rxjs";
import {IApiResponse} from "@shared/models/IApiResponse";
import {IFormConfig} from "@shared/models/IFormConfig";
import {EFormConfig} from "@shared/enums/EFormConfig";

@Injectable({
  providedIn: "root"
})
export class FormSettingsApiService {
  private path = "group/form-settings";

  constructor(private _http: HttpService) {
  }

  // DESC: Get group form settings
  public getSettings(formType: EFormConfig = EFormConfig.All): Observable<IApiResponse<IFormConfig[]>> {
    return this._http.get(`${this.path}`, {formType});
  }

  // DESC: Update group form settings
  public updateSettings(data: IFormConfig[]): Observable<IApiResponse<null>> {
    return this._http.post(`${this.path}`, {data: data});
  }
}
