import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {IDatePickerOptions} from "@shared/models/ILocale";

const formats = {
  "1": {decimal: ".", thousands: ","},
  "2": {decimal: ",", thousands: " "},
  "3": {decimal: ".", thousands: "'"},
  "4": {decimal: ",", thousands: "."}
};

@Injectable()
export class LocaleService {
  locale: BehaviorSubject<string>;
  numberSeparators = {
    "ja": formats[1],
    "ms": formats[1],
    "zh": formats[1],
    "th": formats[1],
    "en": formats[1],
    "he": formats[1],
    "nl": formats[1],
    "tr": formats[1],
    "br": formats[1],
    "ko": formats[1],
    "kk": formats[1],
    "ar": formats[1],
    "sw": formats[2],
    "fr": formats[3],
    "ru": formats[3],
    "uk": formats[3],
    "sk": formats[3],
    "sl": formats[3],
    "pl": formats[3],
    "hu": formats[3],
    "da": formats[4],
    "ro": formats[3],
    "id": formats[3],
    "pt": formats[3],
    "vi": formats[3],
    "lt": formats[3],
    "es": formats[3],
    "bg": formats[3],
    "hr": formats[3],
    "is": formats[3],
    "fi": formats[3],
    "sv": formats[3],
    "no": formats[3],
    "de": formats[3],
    "it": formats[3],
    "ca": formats[3],
    "cs": formats[3],
    "el": formats[3]
  };
  dateFormats = {
    "ar-SA": "DD/MM/yy",
    "bg-BG": "DD.M.yyyy",
    "ca-ES": "DD/MM/yyyy",
    "zh-TW": "yyyy/M/d",
    "cs-CZ": "D.M.yyyy",
    "da-DK": "DD/MM/yyyy",
    "de-DE": "DD.MM.yyyy",
    "el-GR": "D/M/yyyy",
    "en-US": "MM/DD/yyyy",
    "fi-FI": "D.M.yyyy",
    "fr-FR": "DD/MM/yyyy",
    "he-IL": "DD/MM/yyyy",
    "hu-HU": "yyyy. MM. DD.",
    "is-IS": "D.M.yyyy",
    "it-IT": "DD/MM/yyyy",
    "ja-JP": "yyyy/MM/DD",
    "ko-KR": "yyyy-MM-DD",
    "nl-NL": "D-M-yyyy",
    "nb-NO": "DD.MM.yyyy",
    "pl-PL": "yyyy-MM-DD",
    "pt-BR": "D/M/yyyy",
    "ro-RO": "DD.MM.yyyy",
    "ru-RU": "DD.MM.yyyy",
    "hr-HR": "D.M.yyyy",
    "sk-SK": "D. M. yyyy",
    "sq-AL": "yyyy-MM-DD",
    "sv-SE": "yyyy-MM-DD",
    "th-TH": "D/M/yyyy",
    "tr-TR": "DD.MM.yyyy",
    "ur-PK": "DD/MM/yyyy",
    "id-ID": "DD/MM/yyyy",
    "uk-UA": "DD.MM.yyyy",
    "be-BY": "DD.MM.yyyy",
    "sl-SI": "D.M.yyyy",
    "et-EE": "D.MM.yyyy",
    "lv-LV": "yyyy.MM.DD.",
    "lt-LT": "yyyy.MM.DD",
    "fa-IR": "MM/DD/yyyy",
    "vi-VN": "DD/MM/yyyy",
    "hy-AM": "DD.MM.yyyy",
    "az-Latn-AZ": "DD.MM.yyyy",
    "eu-ES": "yyyy/MM/DD",
    "mk-MK": "DD.MM.yyyy",
    "af-ZA": "yyyy/MM/DD",
    "ka-GE": "DD.MM.yyyy",
    "fo-FO": "DD-MM-yyyy",
    "hi-IN": "DD-MM-yyyy",
    "ms-MY": "DD/MM/yyyy",
    "kk-KZ": "DD.MM.yyyy",
    "ky-KG": "DD.MM.yy",
    "sw-KE": "M/D/yyyy",
    "uz-Latn-UZ": "DD/MM yyyy",
    "tt-RU": "DD.MM.yyyy",
    "pa-IN": "DD-MM-yy",
    "gu-IN": "DD-MM-yy",
    "ta-IN": "DD-MM-yyyy",
    "te-IN": "DD-MM-yy",
    "kn-IN": "DD-MM-yy",
    "mr-IN": "DD-MM-yyyy",
    "sa-IN": "DD-MM-yyyy",
    "mn-MN": "yy.MM.DD",
    "gl-ES": "DD/MM/yy",
    "kok-IN": "DD-MM-yyyy",
    "syr-SY": "DD/MM/yyyy",
    "dv-MV": "DD/MM/yy",
    "ar-IQ": "DD/MM/yyyy",
    "zh-CN": "yyyy/M/D",
    "de-CH": "DD.MM.yyyy",
    "en-GB": "DD/MM/yyyy",
    "es-MX": "DD/MM/yyyy",
    "fr-BE": "D/MM/yyyy",
    "it-CH": "DD.MM.yyyy",
    "nl-BE": "D/MM/yyyy",
    "nn-NO": "DD.MM.yyyy",
    "pt-PT": "DD-MM-yyyy",
    "sr-Latn-SR": "D.M.yyyy",
    "sv-FI": "D.M.yyyy",
    "az-Cyrl-AZ": "DD.MM.yyyy",
    "ms-BN": "DD/MM/yyyy",
    "uz-Cyrl-UZ": "DD.MM.yyyy",
    "ar-EG": "DD/MM/yyyy",
    "zh-HK": "D/M/yyyy",
    "de-AT": "DD.MM.yyyy",
    "en-AU": "D/MM/yyyy",
    "es-ES": "DD/MM/yyyy",
    "fr-CA": "yyyy-MM-DD",
    "sr-Cyrl-SR": "D.M.yyyy",
    "ar-LY": "DD/MM/yyyy",
    "zh-SG": "D/M/yyyy",
    "de-LU": "DD.MM.yyyy",
    "en-CA": "DD/MM/yyyy",
    "es-GT": "DD/MM/yyyy",
    "fr-CH": "DD.MM.yyyy",
    "ar-DZ": "DD-MM-yyyy",
    "zh-MO": "D/M/yyyy",
    "de-LI": "DD.MM.yyyy",
    "en-NZ": "D/MM/yyyy",
    "es-CR": "DD/MM/yyyy",
    "fr-LU": "DD/MM/yyyy",
    "ar-MA": "DD-MM-yyyy",
    "en-IE": "DD/MM/yyyy",
    "es-PA": "MM/DD/yyyy",
    "fr-MC": "DD/MM/yyyy",
    "ar-TN": "DD-MM-yyyy",
    "en-ZA": "yyyy/MM/DD",
    "es-DO": "DD/MM/yyyy",
    "ar-OM": "DD/MM/yyyy",
    "en-JM": "DD/MM/yyyy",
    "es-VE": "DD/MM/yyyy",
    "ar-YE": "DD/MM/yyyy",
    "en-029": "MM/DD/yyyy",
    "es-CO": "DD/MM/yyyy",
    "ar-SY": "DD/MM/yyyy",
    "en-BZ": "DD/MM/yyyy",
    "es-PE": "DD/MM/yyyy",
    "ar-JO": "DD/MM/yyyy",
    "en-TT": "DD/MM/yyyy",
    "es-AR": "DD/MM/yyyy",
    "ar-LB": "DD/MM/yyyy",
    "en-ZW": "M/D/yyyy",
    "es-EC": "DD/MM/yyyy",
    "ar-KW": "DD/MM/yyyy",
    "en-PH": "M/D/yyyy",
    "es-CL": "DD-MM-yyyy",
    "ar-AE": "DD/MM/yyyy",
    "es-UY": "DD/MM/yyyy",
    "ar-BH": "DD/MM/yyyy",
    "es-PY": "DD/MM/yyyy",
    "ar-QA": "DD/MM/yyyy",
    "es-BO": "DD/MM/yyyy",
    "es-SV": "DD/MM/yyyy",
    "es-HN": "DD/MM/yyyy",
    "es-NI": "DD/MM/yyyy",
    "es-PR": "DD/MM/yyyy",
    "am-ET": "D/M/yyyy",
    "tzm-Latn-DZ\"": "DD-MM-yyyy",
    "iu-Latn-CA\"": "D/MM/yyyy",
    "sma-NO": "DD.MM.yyyy",
    "mn-Mong-CN": "yyyy/M/D",
    "gd-GB": "DD/MM/yyyy",
    "en-MY": "D/M/yyyy",
    "prs-AF": "DD/MM/yy",
    "bn-BD": "DD-MM-yy",
    "wo-SN": "DD/MM/yyyy",
    "rw-RW": "M/D/yyyy",
    "qut-GT": "DD/MM/yyyy",
    "sah-RU": "MM.DD.yyyy",
    "gsw-FR": "DD/MM/yyyy",
    "co-FR": "DD/MM/yyyy",
    "oc-FR": "DD/MM/yyyy",
    "mi-NZ": "DD/MM/yyyy",
    "ga-IE": "DD/MM/yyyy",
    "se-SE": "yyyy-MM-DD",
    "br-FR": "DD/MM/yyyy",
    "smn-FI": "D.M.yyyy",
    "moh-CA": "M/D/yyyy",
    "arn-CL": "DD-MM-yyyy",
    "ii-CN": "yyyy/M/D",
    "dsb-DE": "D. M. yyyy",
    "ig-NG": "D/M/yyyy",
    "kl-GL": "DD-MM-yyyy",
    "lb-LU": "DD/MM/yyyy",
    "ba-RU": "DD.MM.yy",
    "nso-ZA": "yyyy/MM/DD",
    "quz-BO": "DD/MM/yyyy",
    "yo-NG": "D/M/yyyy",
    "ha-Latn-NG": "D/M/yyyy",
    "fil-PH": "M/D/yyyy",
    "ps-AF": "DD/MM/yy",
    "fy-NL": "D-M-yyyy",
    "ne-NP": "M/D/yyyy",
    "se-NO": "DD.MM.yyyy",
    "iu-Cans-CA\"": "D/M/yyyy",
    "sr-Latn-RS\"": "D.M.yyyy",
    "si-LK": "yyyy-MM-DD",
    "sr-Cyrl-RS\"": "D.M.yyyy",
    "lo-LA": "DD/MM/yyyy",
    "km-KH": "yyyy-MM-DD",
    "cy-GB": "DD/MM/yyyy",
    "bo-CN": "yyyy/M/D",
    "sms-FI": "D.M.yyyy",
    "as-IN": "DD-MM-yyyy",
    "ml-IN": "DD-MM-yy",
    "en-IN": "DD-MM-yyyy",
    "or-IN": "DD-MM-yy",
    "bn-IN": "DD-MM-yy",
    "tk-TM": "DD.MM.yy",
    "bs-Latn-BA": "D.M.yyyy",
    "mt-MT": "DD/MM/yyyy",
    "sr-Cyrl-ME": "D.M.yyyy",
    "se-FI": "D.M.yyyy",
    "zu-ZA": "yyyy/MM/DD",
    "xh-ZA": "yyyy/MM/DD",
    "tn-ZA": "yyyy/MM/DD",
    "hsb-DE": "D. M. yyyy",
    "bs-Cyrl-BA\"": "D.M.yyyy",
    "tg-Cyrl-TJ\"": "DD.MM.yy",
    "sr-Latn-BA\"": "D.M.yyyy",
    "smj-NO": "DD.MM.yyyy",
    "rm-CH": "DD/MM/yyyy",
    "smj-SE": "yyyy-MM-DD",
    "quz-EC": "DD/MM/yyyy",
    "quz-PE": "DD/MM/yyyy",
    "hr-BA": "D.M.yyyy.",
    "sr-Latn-ME\"": "D.M.yyyy",
    "sma-SE": "yyyy-MM-DD",
    "en-SG": "D/M/yyyy",
    "ug-CN": "yyyy-M-D",
    "sr-Cyrl-BA": "D.M.yyyy",
    "es-US": "M/D/yyyy"
  };

  constructor() {
    this.locale = new BehaviorSubject<string>(this.getGroupFromLocalStorage());
  }

  setLocale(val) {
    this.locale.next(val);
  }

  getCurrent() {
    return this.locale.getValue();
  }

  getLocaleForDatepicker(): string {
    return this.getCurrent().split("-")[0];
  }

  getSeparatorsForNumbers(): any {
    const locale = this.getLocaleForDatepicker();
    if (locale && this.numberSeparators.hasOwnProperty(locale)) {
      return this.numberSeparators[locale];
    }
    return this.numberSeparators["en"];
  }

  getLocaleDateString() {
    return this.dateFormats[this.getCurrent()] || "yyyy/MM/DD";
  }

  getDatePickerOptions(): IDatePickerOptions {
    let dateFormat = this.getLocaleDateString().toLowerCase() || "yyyy/MM/DD";
    if (dateFormat.indexOf("yyyy") !== -1) {
      dateFormat = dateFormat.replace("yyyy", "yy");
    }
    return {
      openSelectorOnInputClick: true,
      dateFormat: dateFormat
    };
  }

  private getGroupFromLocalStorage(): string {
    const parse = JSON.parse(localStorage.getItem("currentUser"));
    return parse ? parse.region : "en-US";
  }
}
