export enum CountryISO {
  Afghanistan = "af",
  Albania = "al",
  Algeria = "dz",
  AmericanSamoa = "as",
  Andorra = "ad",
  Angola = "ao",
  Anguilla = "ai",
  AntiguaAndBarbuda = "ag",
  Argentina = "ar",
  Armenia = "am",
  Aruba = "aw",
  Australia = "au",
  Austria = "at",
  Azerbaijan = "az",
  Bahamas = "bs",
  Bahrain = "bh",
  Bangladesh = "bd",
  Barbados = "bb",
  Belarus = "by",
  Belgium = "be",
  Belize = "bz",
  Benin = "bj",
  Bermuda = "bm",
  Bhutan = "bt",
  Bolivia = "bo",
  BosniaAndHerzegovina = "ba",
  Botswana = "bw",
  Brazil = "br",
  BritishIndianOceanTerritory = "io",
  BritishVirginIslands = "vg",
  Brunei = "bn",
  Bulgaria = "bg",
  BurkinaFaso = "bf",
  Burundi = "bi",
  Cambodia = "kh",
  Cameroon = "cm",
  Canada = "ca",
  CapeVerde = "cv",
  CaribbeanNetherlands = "bq",
  CaymanIslands = "ky",
  CentralAfricanRepublic = "cf",
  Chad = "td",
  Chile = "cl",
  China = "cn",
  ChristmasIsland = "cx",
  Cocos = "cc",
  Colombia = "co",
  Comoros = "km",
  CongoDRCJamhuriYaKidemokrasiaYaKongo = "cd",
  CongoRepublicCongoBrazzaville = "cg",
  CookIslands = "ck",
  CostaRica = "cr",
  CôteDIvoire = "ci",
  Croatia = "hr",
  Cuba = "cu",
  Curaçao = "cw",
  Cyprus = "cy",
  CzechRepublic = "cz",
  Denmark = "dk",
  Djibouti = "dj",
  Dominica = "dm",
  DominicanRepublic = "do",
  Ecuador = "ec",
  Egypt = "eg",
  ElSalvador = "sv",
  EquatorialGuinea = "gq",
  Eritrea = "er",
  Estonia = "ee",
  Ethiopia = "et",
  FalklandIslands = "fk",
  FaroeIslands = "fo",
  Fiji = "fj",
  Finland = "fi",
  France = "fr",
  FrenchGuiana = "gf",
  FrenchPolynesia = "pf",
  Gabon = "ga",
  Gambia = "gm",
  Georgia = "ge",
  Germany = "de",
  Ghana = "gh",
  Gibraltar = "gi",
  Greece = "gr",
  Greenland = "gl",
  Grenada = "gd",
  Guadeloupe = "gp",
  Guam = "gu",
  Guatemala = "gt",
  Guernsey = "gg",
  Guinea = "gn",
  GuineaBissau = "gw",
  Guyana = "gy",
  Haiti = "ht",
  Honduras = "hn",
  HongKong = "hk",
  Hungary = "hu",
  Iceland = "is",
  India = "in",
  Indonesia = "id",
  Iran = "ir",
  Iraq = "iq",
  Ireland = "ie",
  IsleOfMan = "im",
  Israel = "il",
  Italy = "it",
  Jamaica = "jm",
  Japan = "jp",
  Jersey = "je",
  Jordan = "jo",
  Kazakhstan = "kz",
  Kenya = "ke",
  Kiribati = "ki",
  Kosovo = "xk",
  Kuwait = "kw",
  Kyrgyzstan = "kg",
  Laos = "la",
  Latvia = "lv",
  Lebanon = "lb",
  Lesotho = "ls",
  Liberia = "lr",
  Libya = "ly",
  Liechtenstein = "li",
  Lithuania = "lt",
  Luxembourg = "lu",
  Macau = "mo",
  Macedonia = "mk",
  Madagascar = "mg",
  Malawi = "mw",
  Malaysia = "my",
  Maldives = "mv",
  Mali = "ml",
  Malta = "mt",
  MarshallIslands = "mh",
  Martinique = "mq",
  Mauritania = "mr",
  Mauritius = "mu",
  Mayotte = "yt",
  Mexico = "mx",
  Micronesia = "fm",
  Moldova = "md",
  Monaco = "mc",
  Mongolia = "mn",
  Montenegro = "me",
  Montserrat = "ms",
  Morocco = "ma",
  Mozambique = "mz",
  Myanmar = "mm",
  Namibia = "na",
  Nauru = "nr",
  Nepal = "np",
  Netherlands = "nl",
  NewCaledonia = "nc",
  NewZealand = "nz",
  Nicaragua = "ni",
  Niger = "ne",
  Nigeria = "ng",
  Niue = "nu",
  NorfolkIsland = "nf",
  NorthKorea = "kp",
  NorthernMarianaIslands = "mp",
  Norway = "no",
  Oman = "om",
  Pakistan = "pk",
  Palau = "pw",
  Palestine = "ps",
  Panama = "pa",
  PapuaNewGuinea = "pg",
  Paraguay = "py",
  Peru = "pe",
  Philippines = "ph",
  Poland = "pl",
  Portugal = "pt",
  PuertoRico = "pr",
  Qatar = "qa",
  Réunion = "re",
  Romania = "ro",
  Russia = "ru",
  Rwanda = "rw",
  SaintBarthélemy = "bl",
  SaintHelena = "sh",
  SaintKittsAndNevis = "kn",
  SaintLucia = "lc",
  SaintMartin = "mf",
  SaintPierreAndMiquelon = "pm",
  SaintVincentAndTheGrenadines = "vc",
  Samoa = "ws",
  SanMarino = "sm",
  SãoToméAndPríncipe = "st",
  SaudiArabia = "sa",
  Senegal = "sn",
  Serbia = "rs",
  Seychelles = "sc",
  SierraLeone = "sl",
  Singapore = "sg",
  SintMaarten = "sx",
  Slovakia = "sk",
  Slovenia = "si",
  SolomonIslands = "sb",
  Somalia = "so",
  SouthAfrica = "za",
  SouthKorea = "kr",
  SouthSudan = "ss",
  Spain = "es",
  SriLanka = "lk",
  Sudan = "sd",
  Suriname = "sr",
  SvalbardAndJanMayen = "sj",
  Swaziland = "sz",
  Sweden = "se",
  Switzerland = "ch",
  Syria = "sy",
  Taiwan = "tw",
  Tajikistan = "tj",
  Tanzania = "tz",
  Thailand = "th",
  TimorLeste = "tl",
  Togo = "tg",
  Tokelau = "tk",
  Tonga = "to",
  TrinidadAndTobago = "tt",
  Tunisia = "tn",
  Turkey = "tr",
  Turkmenistan = "tm",
  TurksAndCaicosIslands = "tc",
  Tuvalu = "tv",
  USVirginIslands = "vi",
  Uganda = "ug",
  Ukraine = "ua",
  UK = "ua",
  UnitedArabEmirates = "ae",
  UnitedKingdom = "gb",
  UnitedStates = "us",
  USA = "us",
  Uruguay = "uy",
  Uzbekistan = "uz",
  Vanuatu = "vu",
  VaticanCity = "va",
  Venezuela = "ve",
  Vietnam = "vn",
  WallisAndFutuna = "wf",
  WesternSahara = "eh",
  Yemen = "ye",
  Zambia = "zm",
  Zimbabwe = "zw",
  ÅlandIslands = "ax",
}
