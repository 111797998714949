import {enableProdMode, importProvidersFrom} from "@angular/core";
import {environment} from "@environments/environment";
import {AppComponent} from "@app/app.component";
import {AppEffects} from "@store/effects";
import {provideEffects} from "@ngrx/effects";
import {provideRouterStore, routerReducer} from "@ngrx/router-store";
import {appReducer, insuranceReducer} from "@app/store";
import {provideStore} from "@ngrx/store";
import {provideAnimations} from "@angular/platform-browser/animations";
import {HttpClientModule, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {AppRoutes} from "@app/app.routes";
import {bootstrapApplication} from "@angular/platform-browser";
import {provideStoreDevtools} from "@ngrx/store-devtools";
import {provideRouter, withInMemoryScrolling} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {CoreModule} from "@app/core";

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(HttpClientModule),
    importProvidersFrom(TranslateModule.forRoot()),
    importProvidersFrom(CoreModule),
    provideRouter(AppRoutes,
      withInMemoryScrolling({
        scrollPositionRestoration: "enabled",
        anchorScrolling: "enabled"
      })
    ),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideStore({
        app: appReducer,
        insurance: insuranceReducer,
        router: routerReducer
      },
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
          strictStateSerializability: false,
          strictActionSerializability: false
        }
      }),
    provideRouterStore(),
    provideEffects([AppEffects]),
    provideStoreDevtools({
      logOnly: !environment.production
    })
  ]
})
  .catch(err => console.error(err));
